/* eslint-disable max-lines */
import React, { forwardRef, LegacyRef, useCallback, useEffect, useState } from 'react';
import { Marker, Typography } from 'fronton-react';
import { getAllowProductComparisons, getPathname } from 'common/selectors';
import { QtyCounter } from 'common/components/qty-counter';
import { patchUrlByLocale } from 'utils';
import { addTrailingSlash } from 'utils/url';
import { cn } from 'utils/classnames';
import { ProductImage } from './product-image';
import { ProductReviews } from './product-reviews';
import { ProductLabel } from './product-label';
import { ProductCartButton } from './product-cart-button';
import { ProductCardProps } from './types';
import { ProductName } from './product-name';
import {
  imageWrapper,
  productLabel,
  productWrapper,
  offerWrapper,
  productNameText,
  productName,
  productId,
  contentWrapper,
  offerOldPriceWrapper,
  productNameTextInWidget,
  MarkerLayout,
  MarkerSize,
  actionButtonsWrapper,
} from './styles';
import { cartButton, compareListActionWrapper, qtyInput } from './common-styles';
import { ProductOnlineOrderOnly } from './product-online-order-only';
import { ProductPriceToDisplay } from './product-price-to-display';
import { MeasurementHintTooltip } from '../measurement-hint';
import { isMeasurableProduct } from '../measurement-hint/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsBot } from 'common/selectors/is-bot';
import { getLoyaltyRewards } from 'features/products/ducks/selectors';
import { BonusLogo } from '@fronton/icons-react/logos';
import { WishlistButton } from './wishlist-button';
import { CompareListAction } from './compare-list-action';

export const DATA_QA_LOYALTY_REWARDS_MARKER: string = 'loyalty-rewards-marker';

export const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    {
      className,
      product,
      type,
      label,
      inBasket,
      qty,
      onClick,
      onPointerDown,
      onCartAdd,
      onCartRemove,
      reviews,
      onQtyUpdate,
      onShoplistToggle,
      inWidget,
      inShoplist,
    },
    ref,
  ) => {
    const pathname = useSelector(getPathname);
    const loyaltyRewards = useSelector(getLoyaltyRewards);
    const { t } = useTranslation();
    const CLOSE_EVENT = 'closeMeasurementTips';
    const productLink = addTrailingSlash(product.productLink);
    const link = patchUrlByLocale(productLink, pathname);
    const reviewsLink = `${link}#reviews`;
    const hasOldPrice = !!product.price?.previous_price;
    const isMeasurable = isMeasurableProduct(product.measurementData);
    const [showMeasurementTip, setShowMeasurementTip] = useState<boolean>(false);
    const hideTip = () => setShowMeasurementTip(false);
    const isBot = useSelector(getIsBot);
    const loyaltyRewardLabel = loyaltyRewards[product.productId]?.label;
    const allowComparisons = useSelector(getAllowProductComparisons);

    const fireGlobalCloseEvent = useCallback(() => {
      document.dispatchEvent(new CustomEvent(CLOSE_EVENT));
    }, []);

    const handleAdd = useCallback(() => {
      onCartAdd(product.productId);
      fireGlobalCloseEvent();
      if (isMeasurable) {
        setShowMeasurementTip(true);
      }
    }, [fireGlobalCloseEvent, isMeasurable, onCartAdd, product.productId]);

    const handleRemove = useCallback(() => {
      onCartRemove?.(product.productId);
      if (isMeasurable) {
        fireGlobalCloseEvent();
      }
    }, [fireGlobalCloseEvent, isMeasurable, onCartRemove, product.productId]);

    const handleUpdate = useCallback(
      (val: number) => {
        onQtyUpdate?.(product.productId, val);
        if (val > 0 && isMeasurable) {
          fireGlobalCloseEvent();
          setShowMeasurementTip(true);
        }
      },
      [fireGlobalCloseEvent, isMeasurable, onQtyUpdate, product.productId],
    );

    useEffect(() => {
      document.addEventListener(CLOSE_EVENT, hideTip, { passive: true });
      return () => document.removeEventListener(CLOSE_EVENT, hideTip);
    }, []);

    useEffect(() => {
      window.addEventListener('scroll', hideTip, { passive: true });
      return () => document.removeEventListener('scroll', hideTip);
    }, []);

    return (
      <div
        className={cn(productWrapper, type, className)}
        data-product-item=""
        data-qa-product=""
        data-qa="product"
        opp-data-product-id={product.productId}
        ref={ref as LegacyRef<HTMLDivElement>}
        {...{ onPointerDown }}
      >
        <a
          href={link}
          className={imageWrapper}
          onClick={() => onClick('productPhotoLink')}
          aria-label={product.displayedName}
          data-qa-product-image=""
          data-qa="product-image"
          tabIndex={-1}
        >
          <ProductImage
            alt={product.displayedName}
            mediaMainPhoto={product.mediaMainPhoto}
            view={type}
            withoutSchema={inWidget}
          />
          {loyaltyRewardLabel && (
            <MarkerLayout>
              <Marker
                data-qa={DATA_QA_LOYALTY_REWARDS_MARKER}
                iconLeft={<BonusLogo className={MarkerSize} />}
                background="secondary-red-dark"
                variant="angular"
                color="text-primary-invert"
                size="s"
                text={loyaltyRewardLabel}
              />
            </MarkerLayout>
          )}
          <ProductLabel labelType={label} className={productLabel} />
        </a>
        <div className={cn(contentWrapper, type)}>
          <Typography variant="caption" color="text-minor" className={productId} data-qa="product-article">
            {t('product-card.product-id')} {product.productId}
          </Typography>
          <ProductName
            data-qa="product-name"
            href={link}
            onClick={() => onClick('productNameLink')}
            className={productName}
            textClassName={inWidget ? productNameTextInWidget : productNameText}
          >
            {product.displayedName}
          </ProductName>
          {reviews && reviews.reviews_number > 0 && (
            <ProductReviews
              data-qa="product-reviews"
              href={reviewsLink}
              rating={reviews.rating}
              reviewsNumber={reviews.reviews_number}
              onClick={() => onClick('productRatingLink')}
            />
          )}
        </div>
        <div className={cn(offerWrapper, type, hasOldPrice ? offerOldPriceWrapper : '')}>
          <ProductPriceToDisplay
            hasOldPrice={hasOldPrice}
            price={product.price}
            productPriceCategory={label}
          />
          <MeasurementHintTooltip
            open={showMeasurementTip}
            placement="top"
            qty={qty}
            price={product.price}
            measurementData={product.measurementData}
          >
            <div onMouseLeave={() => setShowMeasurementTip(false)} className={actionButtonsWrapper}>
              {inBasket ? (
                <QtyCounter
                  uom={
                    product.price.additional_as_main
                      ? product.price.additional_uom_rus
                      : product.price.main_uom_rus
                  }
                  step={product.price.step}
                  min={product.price.step}
                  overriddenValue={qty}
                  className={qtyInput}
                  onChange={(val) => handleUpdate(val)}
                  onRemove={handleRemove}
                />
              ) : (
                <ProductCartButton className={cartButton} isActive={inBasket} onClick={handleAdd} />
              )}

              {onShoplistToggle && (
                <WishlistButton
                  productId={product.productId || ''}
                  size="l"
                  inShoplist={inShoplist}
                  onShoplistToggle={onShoplistToggle}
                />
              )}
            </div>
          </MeasurementHintTooltip>

          {!isBot && label && <ProductOnlineOrderOnly />}
        </div>

        {allowComparisons && (
          <div className={compareListActionWrapper}>
            {/* TODO: Реализовать функционал */}
            <CompareListAction initialActive={false} onClick={() => {}} hideText={true} />
          </div>
        )}
      </div>
    );
  },
);
